import React, { useState } from "react";
import "./faqs.scss";
import { Col, Row } from "antd";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import { ArrFAQ } from "./ArrFAQs";
import { useTranslation } from "react-i18next";
function FAQs() {
  const [faqMenuType, setFaqMenuType] = useState("Popular");
  const { t } = useTranslation();
  return (
    <div className="faqs-container">
      <div className="faqs-title-container">
        <Row justify="center">
          <Col>
            <p className="title">{t("howCanWeHelpU")}</p>
            <p className="desc">{t("gotAQuestion")}</p>
          </Col>
        </Row>
      </div>

      <div className="faqs-desc-container">
        <Row justify="center">
          <Col xl={16} lg={20} xs={23}>
            <Row justify="center">
              <Col>
                <div className="faqs-desc-tittle">{t("faq")} (FAQs)</div>
              </Col>
            </Row>
            <Row gutter={10} justify="center">
              <Col xl={4} lg={6} xs={24}>
                <Row>
                  <Col xl={24} lg={13} sm={6} xs={12}>
                    <div
                      className={`faq-menu-type ${
                        faqMenuType === "Popular" && "active"
                      }`}
                      onClick={() => setFaqMenuType("Popular")}
                    >
                      {t("popular")}
                    </div>
                    <div
                      className={`selectedLine ${
                        faqMenuType === "Popular" && "active"
                      }`}
                    ></div>
                  </Col>
                  <Col xl={24} lg={13} sm={6} xs={12}>
                    <div
                      className={`faq-menu-type ${
                        faqMenuType === "LoanApplication" && "active"
                      }`}
                      onClick={() => setFaqMenuType("LoanApplication")}
                    >
                      {t("loanApplication")}
                    </div>
                    <div
                      className={`selectedLine ${
                        faqMenuType === "LoanApplication" && "active"
                      }`}
                    ></div>
                  </Col>
                  <Col xl={24} lg={13} sm={6} xs={12}>
                    <div
                      className={`faq-menu-type ${
                        faqMenuType === "Repayment" && "active"
                      }`}
                      onClick={() => setFaqMenuType("Repayment")}
                    >
                      {t("Repayment")}
                    </div>
                    <div
                      className={`selectedLine ${
                        faqMenuType === "Repayment" && "active"
                      }`}
                    ></div>
                  </Col>
                  <Col xl={24} lg={13} sm={6} xs={12}>
                    <div
                      className={`faq-menu-type ${
                        faqMenuType === "Others" && "active"
                      }`}
                      onClick={() => setFaqMenuType("Others")}
                    >
                      {t("Others")}
                    </div>
                    <div
                      className={`selectedLine ${
                        faqMenuType === "Others" && "active"
                      }`}
                    ></div>
                  </Col>
                </Row>
              </Col>

              <Col xl={20} lg={18} xs={23}>
                <CAccordion activeItemKey={1}>
                  {ArrFAQ?.map((items) => {
                    return faqMenuType === items.state ? (
                      <CAccordionItem key={items.desc} itemKey={items.Itemkey}>
                        <CAccordionHeader>{t(items.title)}</CAccordionHeader>
                        <CAccordionBody>{t(items.desc)}</CAccordionBody>
                      </CAccordionItem>
                    ) : (
                      ""
                    );
                  })}
                </CAccordion>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FAQs;
