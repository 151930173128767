export const ArrFAQ = [
  {
    Itemkey: 1,
    state: "Popular",
    title: "FAQTitle1",
    desc: "FAQDesc1",
  },
  {
    Itemkey: 1,
    state: "LoanApplication",
    title: "FAQTitle2",

    desc: "FAQDesc2",
  },
  {
    Itemkey: 2,
    state: "LoanApplication",
    title: "FAQTitle3",
    desc: "FAQDesc3",
  },
  {
    Itemkey: 3,
    state: "LoanApplication",
    title: "FAQTitle4",

    desc: "FAQDesc4",
  },
  {
    Itemkey: 4,
    state: "LoanApplication",
    title: "FAQTitle5",

    desc: "FAQDesc5",
  },
  {
    Itemkey: 1,
    state: "Repayment",
    title: "FAQTitle6",

    desc: "FAQDesc6",
  },
  {
    Itemkey: 2,
    state: "Repayment",
    title: "FAQTitle7",

    desc: "FAQDesc7",
  },
  {
    Itemkey: 1,
    state: "Others",
    title: "FAQTitle8",

    desc: "FAQDesc8",
  },
  {
    Itemkey: 2,
    title: "FAQTitle9",
    state: "Others",
    desc: "FAQDesc9",
  },
  {
    Itemkey: 3,
    title: "FAQTitle10",
    state: "Others",
    desc: "FAQDesc10",
  },
];
