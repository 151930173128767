import React, { useState } from "react";
import "./home.scss";
import { Card, Carousel, Col, Row, Slider } from "antd";
import Step1 from "../../images/step1.png";
import Step2 from "../../images/step2.png";
import Step3 from "../../images/step3.png";
import StepDivider from "../../images/step-divider.png";
import PhoneWhyUs from "../../images/phone-home.png";
import UpfrontPayment from "../../images/phone-home-no-upfront-payment.png";
import SimpleEasy from "../../images/phone-home-simple-easy-install.png";
import ZeroInterest from "../../images/phone-home-zero-interest.png";
import OneStop from "../../images/phone-home-one-stop-centre.png";
import DownloadPhone from "../../images/home-download-phone.png";
import GooglePlay from "../../images/home-download-google.png";
import DownloadQR from "../../images/home-download-qr.png";
import { ArrCustomerFeedback1 } from "./ArrayHome";
import { useTranslation } from "react-i18next";
function Home() {
  const [amount, setAmount] = useState("50");
  const { t } = useTranslation();

  function onChangeSlider(values) {
    setAmount(values);
  }

  async function handleDownload(){
    fetch('https://antsloan.sgp1.digitaloceanspaces.com/version.json')
        .then(response => response.json())
        .then(data => {window.open(data[0].url, '_blank').focus();});
  }
  
  return (
    <div className="home-container">
      <div className="home-first-page">
        <Row justify="center">
          <Col xl={24} xs={23}>
            <div className="home-first-page-title">{t("zeroInterest")}</div>
          </Col>

          <Col xl={24} xs={23}>
            <div className="home-first-page-desc">
              {t("upfrontPaymentAndInterestFree")}
            </div>
          </Col>

          <Col xl={8} xs={23}>
            <div className="home-first-page-card">
              <Card>
                <div className="card-title">
                  {t("iWantToApplyForA")}
                  <span>RM {amount}</span> {t("loan")}
                </div>

                <div className="card-slider">
                  <Slider
                    min={50}
                    max={1000}
                    onChange={onChangeSlider}
                    keyboard
                  />
                </div>

                <div className="card-desc">
                  <div className="desc-container first">
                    <div className="title">0%</div>
                    <div className="desc">{t("interestRate")}</div>
                  </div>
                  <div className="desc-container second">
                    <div className="title">RM {amount}</div>
                    <div className="desc">{t("repaymentAmount")}</div>
                  </div>
                  <div className="desc-container third">
                    <div className="title">120 {t("days")}</div>
                    <div className="desc"> {t("tenurePeriod")}</div>
                  </div>
                </div>

                <div className="card-button">
                <div className="apply-button" onClick={handleDownload} >{t("applyNow")}</div>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>

      <div className="home-second-page">
        <Row justify="center">
          <Col xs={23}>
            <div className="home-second-page-title">
              <p>{t("homeDesc1")}</p>
            </div>
          </Col>
        </Row>

        <Row justify="center" className="home-second-page-desc">
          <Col xl={18} sm={20} xs={23}>
            <Row justify="center" gutter={[10, 20]}>
              <Col
                xl={4}
                lg={18}
                sm={20}
                xs={24}
                className="step-by-step step1"
              >
                <Row justify="center">
                  <Col xl={8} sm={4} xs={7}>
                    <div className="image-step">
                      <img src={Step1} alt="step1" />
                    </div>
                  </Col>

                  <Col xl={24} sm={20} xs={17}>
                    <Row>
                      <Col xl={24} xs={24}>
                        <div className="title">{t("signUp")}</div>
                      </Col>

                      <Col xs={24}>
                        <div className="desc">{t("step1Desc")}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xl={4} xs={0}>
                <div className="step-divider"></div>
              </Col>

              <Col
                xl={4}
                lg={18}
                sm={20}
                xs={24}
                className="step-by-step step2"
              >
                <Row justify="center">
                  <Col xl={8} sm={4} xs={7}>
                    <div className="image-step">
                      <img src={Step2} alt="step2" />
                    </div>
                  </Col>

                  <Col xl={24} sm={20} xs={17}>
                    <Row>
                      <Col xl={24} xs={24}>
                        <div className="title">{t("signUp")}</div>
                      </Col>
                      <Col xs={24}>
                        <div className="desc">{t("step2Desc")}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xl={4} xs={0}>
                <div className="step-divider"></div>
              </Col>

              <Col
                xl={4}
                lg={18}
                sm={20}
                xs={24}
                className="step-by-step step3"
              >
                <Row justify="center">
                  <Col xl={8} sm={4} xs={7}>
                    <div className="image-step">
                      <img src={Step3} alt="step3" />
                    </div>
                  </Col>

                  <Col xl={24} sm={20} xs={17}>
                    <Row>
                      <Col xl={24} xs={24}>
                        <div className="title">{t("signUp")}</div>
                      </Col>
                      <Col xs={24}>
                        <div className="desc">{t("step2Desc")}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="home-third-page">
        <Row justify="center">
          <Col xl={20} sm={23} xs={23}>
            <div className="home-third-page-title">
              <p>{t("whyUs")}</p>
            </div>

            <Row
              justify="center"
              className="home-third-page-desc"
              gutter={[20, 20]}
            >
              <Col xl={8} sm={9} xs={24}>
                <div className="upfront-simple-container">
                  <Row gutter={10}>
                    <Col xl={10} xs={8}>
                      <div className="why-us-image">
                        <img src={UpfrontPayment} alt="no-upfront" />
                      </div>
                    </Col>

                    <Col xl={14} xs={16}>
                      <div className="why-us-desc">
                        <div className="title">{t("noUpfrontPayment")}</div>
                        <div className="desc">{t("whyUsDesc1")}</div>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={10} justify="center">
                    <Col xl={10} xs={8}>
                      <div className="why-us-image">
                        <img src={SimpleEasy} alt="simple-easy" />
                      </div>
                    </Col>

                    <Col xl={14} xs={16}>
                      <div className="why-us-desc">
                        <div className="title">
                          {t("simpleandEasytoInstall")}
                        </div>
                        <div className="desc">{t("whyUsDesc2")}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xl={4} sm={6} xs={0}>
                <div className="phone-home">
                  <img src={PhoneWhyUs} alt="phone-home" />
                </div>
              </Col>

              <Col xl={8} sm={9} xs={24}>
                <div className="zero-stop-container">
                  <Row gutter={10}>
                    <Col xl={5} xs={8}>
                      <div className="why-us-image">
                        <img src={ZeroInterest} alt="zero-interest" />
                      </div>
                    </Col>

                    <Col xl={14} xs={16}>
                      <div className="why-us-desc">
                        <div className="title">{t("zeroInterest1")}</div>
                        <div className="desc">{t("whyUsDesc3")}</div>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={10}>
                    <Col xl={5} xs={8}>
                      <div className="why-us-image">
                        <img src={OneStop} alt="one-stop" />
                      </div>
                    </Col>

                    <Col xl={14} xs={16}>
                      <div className="why-us-desc">
                        <div className="title">{t("oneStopCentre")}</div>
                        <div className="desc">{t("whyUsDesc4")}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* <div className="home-forth-page">
        <div className="forth-page-title">
          <p>Some of Our Customer Testimonials</p>
        </div>

        <div className="forth-page-desc">
          <p>Find out how we make your life better</p>
        </div>

        <Carousel autoplay>
          <div className="customer-feedback-container">
            {ArrCustomerFeedback1?.map((items) => {
              return (
                <div key={items.name}>
                  <Card>
                    <div className="">2</div>
                  </Card>
                </div>
              );
            })}
          </div>
          <div className="customer-feedback-container">
            {ArrCustomerFeedback1?.map((items) => {
              return (
                <div className="customer-feedback-card">
                  <Card key={items.name}>
                    <div className="">1 </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </Carousel>
      </div> */}

      <div className="fifth-page-container">
        <Row justify="center" className="background-fifth-page">
          <Col xl={16} sm={23} xs={23}>
            <Row justify="center">
              <Col xl={14} lg={16} xs={0} className="fifth-page-image"></Col>

              <Col
                xl={10}
                lg={8}
                sm={16}
                xs={24}
                style={{ marginBottom: "40px" }}
              >
                <Row>
                  <Col>
                    <div className="fifth-page-title">
                      <p>{t("downloadAntsloanToday")}</p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="fifth-page-desc">
                      <p>{t("fifthDesc")}</p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24}>
                    <Card>
                      <div className="qr-google-container">
                        <div className="qr-container download">
                          <p>{t("scanQr")}</p>
                          <img src={DownloadQR} alt="downloadqr" />
                        </div>

                        <div className="google-container download">
                          <p>{t("downloadOn")} :</p>
                          <img src={GooglePlay} alt="googleplay" />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
