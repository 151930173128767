import FiveMin from "../../images/5-min.png";
import ThreeMin from "../../images/3-min.png";

export const arrAboutUs = [
  {
    title: "Submission",
    desc: "aboutUsDesc1",
    image: FiveMin,
    desc2: "DownloadNow",
    classname: "five-min",
  },
  {
    title: "Review",
    desc: "aboutUsDesc2",
    image: ThreeMin,
    desc2: "",
    classname: "three-min",
  },
  {
    title: "Disbursement",
    desc: "aboutUsDesc3",
    image: ThreeMin,
    desc2: "",
    classname: "three-min",
  },
];
